body, html {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  /* height: calc(100vh + 1px); */
  height: 100%;
  /* background-color: #b41818; */
  background-image: 
    /* Gradient layer */
    /* linear-gradient(137deg, #525252 0%, #202020 100%), */
    linear-gradient(137deg, rgba(98, 98, 98, 0.6) 0%, rgba(23, 23, 23, 0.6) 100%),
    /* Image layer */
    url('../../public/textured-backdrop-low.jpg');
  /* Background properties */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  /* font-family: 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  font-family: "Open Sans", "PingFang SC", "Microsoft YaHei", "Helvetica Neue", "Hiragino Sans GB", "WenQuanYi Micro Hei", Arial, "sans-serif";
  /* font-family: Audiowide-Regular; */
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.33px;
  scroll-behavior: smooth;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.header {
  position: fixed;
  top: 0px;
  height: 60px;
  width: 100%;
  z-index: 2;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items:  center;
}

.menu {
  display: none;
  font-weight: 600;
  position: absolute;
  right: 50px;
  top: 30px;
  font-size: 15px;
  a, a:link, a:visited, a:focus, a:hover, a:active  {
    color: #464444;
    text-decoration: none;
    padding: 0 8px;
  }
  a:hover {
    text-decoration: underline;
  }

  a.menu-primary {
    border-radius: 20px;
    padding: 5px 10px;
    margin-left: 12px;
    background-color: #0070d5;
    color: white;
  }

  a:hover.menu-primary {
    text-decoration: none;
  }

}

.section {
  scroll-snap-align: start;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;
  z-index: 1;
  position: relative;
}

.section-content {
  width: 100%;
  margin: 18px;
  margin: 0;
  padding: 0 18px 18px 18px;
  /* background: rgba(0, 0, 0, 0.2); */

  ul {
    padding-left: 25px;
  }
}


.sign,
.contacts {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin-top: 20px;
  border-top: 1px solid white;
}

.contacts a {
  display: inline-block;
  margin-top: 20px; /* Optional: Adds space between links */
  margin-left: 15px;
  text-decoration: none; /* Optional: Removes underline from links */
}

/* Optional: hover effect for links */
.contacts a:hover {
  text-decoration: underline;
}

.contacts {
  /* position: absolute;
  right: 40px; */

  a {
    background-repeat: no-repeat;
    padding-left: 28px;  /* Adjust padding to ensure text does not overlap the icon */
    background-size: 20px 20px;  /* Adjust size as necessary */
    background-position: 0 center;  /* Align the icon with the text vertically */
  }

  .instagram {
    background-image: url('../../public/iconmonstr-instagram-11.svg');
  }
  
  .email {
    background-image: url('../../public/iconmonstr-email-2.svg');
  }
}



form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

input {
  display: inline-block;
  border-radius: 20px;
  padding: 10px 20px;
}

input[type="email"] {
  width: 70%;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  font-size: 16px;
}

input[type="submit"] {
  width: 30%;
  border: 0px;
}


#board {
  position: fixed;
  right: 0;
  width: 100%;
  top: 60px;
  height: calc(60vh - 60px);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

#bottom-board, #top-board {
  position: absolute;
  height: 90%;
  margin-left: 24px;
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(4);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-out {
  animation: zoomOut 0.4s forwards;
  animation-timing-function:  ease-in-cubic;
}

.animate-in {
  animation: zoomIn 0.4s forwards;
  animation-timing-function:  ease-out-cubic;
}

a, a:link, a:visited, a:focus, a:hover, a:active, a:hover  {
  color: white;
  text-decoration: none;
}


/* #webgl-background {
  position: fixed;
  top: 140px;
  right: 60px;
  width: 45%;
  height: 70%;
  z-index: 0;
  background-color: white;
  border-radius: 20px;
} */


ul, h2 {
  margin-bottom: 5px;
}

h1 {
  font-size: large;
  font-weight: 800;
  margin-top: 0px;
  /* border-bottom: 2px solid white; */
}

h2 {
  font-size: medium;
  color: #cccccc;
  font-weight: 400;
  /* -webkit-text-stroke: 1px #464444;  */
  /* text-align: justify;
  text-justify: inter-word; */
}

b {
  color: #0070d5;
  /* color: #ffffff; */
}

.button-container {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.button {
  font-weight: 600;
  cursor: pointer;
  border-radius: 30px;
  padding: 8px 16px;
  margin: 10px;
  font-size: 16px;
  text-align: center;
}

.button-primary {
  background-color: #0070d5;
  color: white;
}

.button-secondary {
  border: 1px solid white;
}

.hidden { display: none; }
.loader {
    border: 6px solid white;
    border-top: 6px solid #0070d5;
    border-radius: 50%;
    width: 20px;
    height: 17px;
    margin: 0 2px 0 10px;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* .section-content {
  background-color: #222222;
  border-radius: 30px;
  padding: 0px 40px 40px 10px;
} */

@media (orientation: landscape) {
  body, html {
    height: 100vh;
  }

  .header {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  #board {
    top: 80px;
    height: calc(60vh - 80px);
  }

  .logo {
    margin: 22px 40px;
  }

  .section {
    align-items: center;
    width: 50%;
  }

  .section-content {
    padding-top: 80px;
    width: 100%;
    margin: 40px;
    background: transparent;
  }

  h1 {
    font-size: large;
  }
  
  h2 {
    font-size: medium;
  }

  .button-container {
    justify-content: center;
    flex-direction: row;
  }

  .button {
    border-radius: 30px;
    display: inline-block;
    padding: 12px 24px;
    margin: 14px;
  }

  /* #webgl-output {
    height: calc(100vh - 80px);
    width: 50%;
  } */

  #board {
    height: calc(100vh - 80px);
    width: 55%;
  }

  #bottom-board, #top-board {
    height: 80%;
  }

  .menu {
    display: block;
  }

  h1 {
    font-size:x-large;
  }
  
  h2 {
    font-size: large;
  }
}

/* @media (orientation: portrait) {
  body {
    flex-direction: column;
  }
} */

/* #notify-modal-container {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(23, 23, 23, 0.6);
  opacity: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

#notify-modal-container.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s;
}

#notify-modal {
  border-radius: 20px;
  width: 50%;
  height: 50%;
  position: absolute;
  background-color: white;
  padding: 30px;
  box-shadow: 0px 0px 53px 0px rgba(0,0,0,0.75);
  display: flex;
  justify-content: center; 
  align-items: center;
} */


/* .footer {
  font-weight: 600;
  position: fixed;
  bottom: 0px;
  height: 50px;
  width: 100%;
  z-index: 2;
  font-size: 15px;
  padding: auto;
  background-color: white;
  color: #464444;
  display: flex;
  justify-content: center;
  align-items: center; 

  a, a:link, a:visited, a:focus, a:hover, a:active, a:hover  {
    color: #464444;
  }
} */
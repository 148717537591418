body, html {
  color: #fff;
  letter-spacing: .33px;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  background-image: linear-gradient(137deg, #62626299 0%, #17171799 100%), url("textured-backdrop-low.e3643eb3.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Open Sans, PingFang SC, Microsoft YaHei, Helvetica Neue, Hiragino Sans GB, WenQuanYi Micro Hei, Arial, "sans-serif";
  font-size: 14px;
  overflow-y: scroll;
}

.header {
  z-index: 2;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
  position: fixed;
  top: 0;
}

.menu {
  font-size: 15px;
  font-weight: 600;
  display: none;
  position: absolute;
  top: 30px;
  right: 50px;

  & a, & a:link, & a:visited, & a:focus, & a:hover, & a:active {
    color: #464444;
    padding: 0 8px;
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
  }

  & a.menu-primary {
    color: #fff;
    background-color: #0070d5;
    border-radius: 20px;
    margin-left: 12px;
    padding: 5px 10px;
  }

  & a:hover.menu-primary {
    text-decoration: none;
  }
}

.section {
  scroll-snap-align: start;
  color: #fff;
  z-index: 1;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  display: flex;
  position: relative;
}

.section-content {
  width: 100%;
  margin: 0;
  padding: 0 18px 18px;

  & ul {
    padding-left: 25px;
  }
}

.sign, .contacts {
  border-top: 1px solid #fff;
  margin-top: 20px;
}

.contacts a {
  margin-top: 20px;
  margin-left: 15px;
  text-decoration: none;
  display: inline-block;
}

.contacts a:hover {
  text-decoration: underline;
}

.contacts {
  & a {
    background-position: 0;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-left: 28px;
  }

  & .instagram {
    background-image: url("iconmonstr-instagram-11.b83aeeca.svg");
  }

  & .email {
    background-image: url("iconmonstr-email-2.d008b98f.svg");
  }
}

form {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

input {
  border-radius: 20px;
  padding: 10px 20px;
  display: inline-block;
}

input[type="email"] {
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  width: 70%;
  font-size: 16px;
}

input[type="submit"] {
  border: 0;
  width: 30%;
}

#board {
  z-index: 1;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(60vh - 60px);
  display: flex;
  position: fixed;
  top: 60px;
  right: 0;
}

#bottom-board, #top-board {
  height: 90%;
  margin-left: 24px;
  position: absolute;
}

@keyframes zoomOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(4);
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(4);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-out {
  animation: .4s forwards zoomOut;
  animation-timing-function: ease-in-cubic;
}

.animate-in {
  animation: .4s forwards zoomIn;
  animation-timing-function: ease-out-cubic;
}

a, a:link, a:visited, a:focus, a:hover, a:active, a:hover {
  color: #fff;
  text-decoration: none;
}

ul, h2 {
  margin-bottom: 5px;
}

h1 {
  margin-top: 0;
  font-size: large;
  font-weight: 800;
}

h2 {
  color: #ccc;
  font-size: medium;
  font-weight: 400;
}

b {
  color: #0070d5;
}

.button-container {
  flex-direction: column;
  justify-content: left;
  display: flex;
}

.button {
  cursor: pointer;
  text-align: center;
  border-radius: 30px;
  margin: 10px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
}

.button-primary {
  color: #fff;
  background-color: #0070d5;
}

.button-secondary {
  border: 1px solid #fff;
}

.hidden {
  display: none;
}

.loader {
  border: 6px solid #fff;
  border-top-color: #0070d5;
  border-radius: 50%;
  width: 20px;
  height: 17px;
  margin: 0 2px 0 10px;
  animation: 2s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (orientation: landscape) {
  body, html {
    height: 100vh;
  }

  .header {
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 80px;
    display: flex;
  }

  #board {
    height: calc(60vh - 80px);
    top: 80px;
  }

  .logo {
    margin: 22px 40px;
  }

  .section {
    align-items: center;
    width: 50%;
  }

  .section-content {
    background: none;
    width: 100%;
    margin: 40px;
    padding-top: 80px;
  }

  .button-container {
    flex-direction: row;
    justify-content: center;
  }

  .button {
    border-radius: 30px;
    margin: 14px;
    padding: 12px 24px;
    display: inline-block;
  }

  #board {
    width: 55%;
    height: calc(100vh - 80px);
  }

  #bottom-board, #top-board {
    height: 80%;
  }

  .menu {
    display: block;
  }

  h1 {
    font-size: x-large;
  }

  h2 {
    font-size: large;
  }
}
/*# sourceMappingURL=index.8d6ed13b.css.map */
